import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;

// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.reveal';
import 'foundation-sites/dist/js/plugins/foundation.tabs';

import Accordions from './modules/Accordions';

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	// mobile hamburger menu
	$('.hamburger').on('click', function (e) {
		$('.hamburger').toggleClass('is-active');
		$('.nav-menu-container').toggleClass('is-active');
		$('.nav-main').toggleClass('menu-active');		
		e.preventDefault();
	});
	

	// modal open
	$('.modal-link').on('click', function() {
		$('#video-modal').foundation('open');		     
		$('.embed-player').attr("src", $(this).attr('data-video-link'));
		$('.embed-player').attr("title", $(this).attr('data-video-title'));
	});

	// reveal video close and stop
	$('.reveal-overlay').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .close-button').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$(document).on('keyup',function(evt) {
		if (evt.keyCode == 27) {
			$('.reveal-video iframe').attr('src', '');
		}
	});

	// mobile tabs
	// if ($(window).width() < 640) {
	// 	$('.tabs-title a[href*="#"]')
	// 	.not('[href="#"]')
	// 	.not('[href="#0"]')
	// 	.click(function(event) {
	// 	  if (
	// 		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
	// 		&& 
	// 		location.hostname == this.hostname
	// 	  ) {
	// 		var target = $(this.hash);
	// 		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
	// 		if (target.length) {
	// 		  event.preventDefault();
	// 		  $('html, body').animate({
	// 			scrollTop: target.offset().top
	// 		  }, 1000, function() {
	// 			var $target = $(target);
	// 			$target.focus();
	// 			if ($target.is(":focus")) {
	// 			  return false;
	// 			} else {
	// 				$target.find('a').focus();
	// 			};
	// 		  });
	// 		}
	// 	  }
	// 	});		
	// } else {

	// }

	// accordions
	const accordion = new Accordions();
	accordion.init();	
});
